<header-menu></header-menu>

<intro></intro>

<section id="accueil" class="p-0" (window:resize)="screenSizeChange()">
  <img *ngIf="infosGeneral?.homeImgUrl != null" src="{{imagesRoot}}{{infosGeneral.homeImgUrl}}" width="100%">
  <!-- <instafeed></instafeed> -->
</section>

<!-- BIOGRAPHIE -->
<section id="biographie" class="bg-color-dark">
  <div class="container-fluid pb-5">
    <!-- <div class="top-puce"><img src="assets/imgs/triangle.png"/></div> -->
    <div class="section-title">
      <h2 class="text-color-light">Biographie</h2>
      <img src="assets/imgs/separator.png" height="9px" width="48px">
    </div> 
    <div class="row mt-3 mx-3">
      <div class="col-md-5 p-0">
          <img *ngIf="infosGeneral?.bioImgUrl != null" src="{{imagesRoot}}{{infosGeneral.bioImgUrl}}" class="img-fluid img-thumbnail">
      </div>
      <div class="col-md-7 p-0" [class.pt-5]="widthLevel != 'md'" [class.pl-5]="widthLevel == 'md'" [innerHTML]="infosGeneral?.biographie"></div>
    </div>
  </div>  
</section>

  <!-- IBLIO -->
  <section id="biblio bg-color-section">
    <div class="container-fluid">
      <div class="top-puce"><img src="assets/imgs/triangle-n.png"/></div>
      <div class="section-title">
        <div class="row m-0 justify-content-center align-items-center">
          <div class="col-auto pt-2"><h2>BIBLIOGRAPHIE SéLECTIVE</h2></div>
          <!-- <div class="col-auto p-0"><img src="assets/imgs/picto-coeur.jpg" width="17px"></div> -->
        </div>
      </div>
      <div class="mx-3 wrapper"> 
        <div class="pb-2"> 
          <p><strong>Le Jardin des Lumières</strong></p>
          <p>Texte de Juliette Adam  / Père Castor Flammarion 2023</p>
        </div>
        <div class="pb-2"> 
          <p><strong>Les contes de la Chouette (4 Tomes)</strong></p>
          <p>Textes d’Eric-Emmanuel Schmitt / Hachette 2022 - 2023</p>
        </div>
        <div class="pb-2"> 
          <p><strong>POM - La révolte des enfants sauvages (2 Tomes)</strong></p>
          <p>Textes de Caroline Solé / Albin Michel 2021</p>
        </div>
        <div class="pb-2"> 
          <p><strong>Tout ce que j’aimerais dire aux ﬁlles</strong></p>
          <p>Texte de Sophie Carquin / Albin Michel 2020</p>
        </div>
        <div class="pb-2"> 
          <p><strong>Mr Leblanc et l’homme en noir</strong></p>
          <p>Texte de Bernard Villiot / Gautier Languereau 2020</p>
        </div>
        <div class="pb-2"> 
          <p><strong>Le chemin de Jada</strong></p>
          <p>Texte de Laura Nsafou / Cambourakis 2020</p>
        </div>
        <div class="pb-2"> 
          <p><strong>Comme un million de papillons noirs</strong></p>
          <p>Texte de Laura Nsafou / Cambourakis 2018</p>
        </div>
        <div class="pb-2"> 
          <p><strong>Le fontôme de Canterville</strong></p>
          <p>Texte de Oscar Wilde / Marmaille 2013</p>
        </div>
        <div class="section-title mt-3">
          <div class="row m-0 justify-content-center align-items-center">
            <div class="col-auto pt-2"><h2 class="text-color-primary">à PARAîTRE PROCHAINEMENT</h2></div>
            <!-- <div class="col-auto p-0"><img src="assets/imgs/picto-coeur.jpg" width="17px"></div> -->
          </div>
        </div>
        <div class="pb-2"> 
          <p class="text-color-primary"><strong>Les loups des quatre saisons</strong></p>
          <p class="text-color-primary">Texte de Alyssa Béjaoui / Père Castor Flammarion 2024</p>
        </div>
        <div class="pb-2"> 
          <p class="text-color-primary"><strong>Le livre de la nature</strong></p>
          <p class="text-color-primary">Poèmes de Marcel Pagnol, avec Nicolas Pagnol / Michel Lafon 2024</p>
        </div>
      </div>
     
    </div>
  </section>

<!-- PORTFOLIO -->
<div id="portfolio"></div>
<ng-container *ngFor="let projet of projets">
  <!-- PROJET -->
  <section id="projet-{{projet.id}}" class="projet p-0">
    <img *ngIf="projet.banniereUrl != null" src="{{imagesRoot}}{{projet.banniereUrl}}" width="100%">
    <div class="container-fluid py-5">
      <div class="section-title">
        <h2 [innerHTML]=projet.titre></h2>
        <img src="assets/imgs/separator.png" height="9px" width="48px">
        <p [innerHTML]=projet.sousTitre></p>
      </div> 
      <div class="row mt-3 mx-3">
        <div class="col-md-5 p-0">
          <img-carousel 
            [title]="'projetCarousel-'+projet.id" 
            [carouselSlides]="projet.carousel" 
            [imgClickEnable]="true"             
            [interval]=5000
            [showIndicators]=true>
          </img-carousel>  
        </div>
        <div class="col-md-7 p-0" [class.pt-5]="widthLevel != 'md'" [class.pl-5]="widthLevel == 'md'">
          <p *ngIf="projet.resume != '.'" [innerHTML]=projet.resume></p>
          <p *ngIf="projet.citation" class="pt-4 projet-citation" [innerHTML]="'«&nbsp;'+projet.citation+'&nbsp;»'"></p>
          <div class="pt-4 text-right" *ngIf="projet.cabochonUrl != null">
            <img src="{{imagesRoot}}{{projet.cabochonUrl}}" [style.width.%]="projet.cabochonSize">
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- PRODUITS ASSOCIES -->
  <section class="produits-associes p-0" *ngIf="projetUtils.hasProduitsAssocies(projet)">
    <div class="container-fluid pb-4 px-4">
      <hr class="w-100">
      <!-- <div class="text-center"><img src="assets/imgs/separator.png" height="9px" width="48px"></div> -->
      <div class="row mt-4">
        <div class="col-auto" [class.pr-5]="widthLevel == 'md'" *ngFor="let wrapper of projetUtils.getProduitsAssocies(projet)">
          <div class="titre">{{wrapper.titre}}</div>
          <p class="sous-titre" *ngFor="let produitAssocie of wrapper.produitsAssocies">{{produitAssocie.titre}}</p>
          <div class="row align-items-center py-3">
            <ng-container *ngFor="let produitAssocie of wrapper.produitsAssocies">
              <div class="col-auto p-2" *ngIf="produitAssocie.imageUrl != null">
                <img src="{{imagesRoot}}{{produitAssocie.imageUrl}}" width="100px">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>  
  </section>

  <!-- PRESSE -->
  <section class="presse bg-color-section" *ngIf="projetUtils.hasPresseItems(projet)">
    <div class="container-fluid">
      <div class="section-title">
        <div class="row m-0 justify-content-center align-items-center">
          <div class="col-auto pt-2"><h2>LA PRESSE EN PARLE</h2></div>
          <div class="col-auto p-0"><img src="assets/imgs/picto-coeur.jpg" width="17px"></div>
        </div>
      </div>
      <div class="pt-4 px-5" *ngFor="let presseItem of projet.presseItems">
        <p class="presse-citation" [innerHTML]="'«&nbsp;'+presseItem.citation+'&nbsp;»'"></p>
        <p class="media-origine" [innerHTML]=presseItem.media></p>
      </div>      
    </div>
  </section>
  
  <!-- MEDIAS -->
  <section class="medias bg-color-dark" *ngIf="projetUtils.hasPodcastsVideo(projet) || projetUtils.hasPodcastsAudio(projet)">
    <p class="podcasts-desc pl-4 text-color-light" *ngIf="projet.podcastsVideoDesc != null" [innerHTML]="projet.podcastsVideoDesc"></p>
    <div class="container-fluid pb-3">
      <div class="top-puce"><img src="assets/imgs/triangle-b.png"/></div>
      <!-- VIDEO -->
      <ng-container *ngIf="projetUtils.hasPodcastsVideo(projet)">
        <div class="pt-5 px-3" *ngFor="let podcast of projetUtils.getPodcastsVideo(projet)">
          <vime-player controls>
            <vime-youtube cookies="true" [attr.video-id]="podcast.url"></vime-youtube>
            <vime-ui></vime-ui>
          </vime-player>
        </div>
      </ng-container>
      <!-- AUDIO -->
      <ng-container *ngIf="projetUtils.hasPodcastsAudio(projet)">
        <p class="podcasts-desc pl-4 text-color-light" *ngIf="projet.podcastsAudioDesc != null" [innerHTML]="projet.podcastsAudioDesc"></p>
        <div class="row mt-3 mx-3">
          <div class="p-4 col-md-6 col-lg-4" *ngFor="let podcast of projetUtils.getPodcastsAudio(projet)">
            <div class="row podcast-audio align-items-center">
              <div class="col-12">
                <p class="pt-3 text-color-light" *ngIf="podcast.titre != null" [innerHTML]="podcast.titre"></p>
              </div>
              <div class="col-auto p-3">
                <img src="{{imagesRoot}}{{podcast.imageUrl}}" width="150px">
              </div>
              <div class="col">
                <vime-player 
                theme="dark"
                style="--vm-player-theme: #A7720C;">
                <vime-audio>
                  <source data-src="{{podcastsRoot}}{{podcast.url}}" type="audio/mp3" />
                </vime-audio>
                <vime-default-ui></vime-default-ui>
              </vime-player>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>  
  </section>
    
</ng-container>

<section id="contact">
  <div class="container-fluid">
    <div class="section-title">
      <h2>CONTACT</h2>
      <img src="assets/imgs/separator.png" height="9px" width="48px">
    </div> 
    <div class="text-center">
      <img class="mt-3" src="assets/imgs/picto-mail.png" width="30px"/>
      <p>contact@barbarabrun.com</p>
      <a href="https://www.instagram.com/barbarabrun/" target="_blank">
        <img class="mt-3" src="assets/imgs/picto-insta.png" width="40px"/>
        <p>barbarabrun</p>
      </a>
      <img class="mt-3" src="assets/imgs/feuille.jpg" width="100px"/>
    </div>
  </div>  
</section>

<btn-top-return></btn-top-return>

<custom-footer></custom-footer>